.p-stepper {
    .p-stepper-header {
        .p-stepper-number {
            &::after {
                content: ' ';
                position: absolute;
                height: 100%;
                box-shadow: 0px 0.5px 0px 0px rgba(0, 0, 0, 0.06), 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
            }
        }
        
        &.p-highlight {
            .p-stepper-number {
                background: $stepsItemBg;
                color: $primaryColor;
            }

            .p-stepper-title {
                color: $primaryColor;
            }
        }
    }
}