.p-datatable {
    .p-datatable-tbody {
        > tr {
            &.p-datatable-dragpoint-top > td {
                box-shadow: inset 0 2px 0 0 $primaryColor;
            }

            &.p-datatable-dragpoint-bottom > td {
                box-shadow: inset 0 -2px 0 0 $primaryColor;
            }

            &:has(+ .p-highlight) {
                > td {
                    border-bottom-color: scale-color($highlightBg, $lightness: -10%);
                }
            }

            &.p-highlight {
                > td {
                    border-bottom-color: scale-color($highlightBg, $lightness: -10%);
                }
            }
        }
    }
}