@use 'sass:math';

.p-steps {
    .p-steps-item {
        .p-menuitem-link {
            .p-steps-number {
                border-width: 2px;
                transition: $actionIconTransition;
            }

            .p-steps-title {
                font-weight: $stepsItemActiveFontWeight;
                transition: $actionIconTransition;
            }
        }

        .p-steps-number {
            position: relative;
            font-weight: 500;

            &::after {
                content: ' ';
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                box-shadow: 0px 0.5px 0px 0px rgba(0, 0, 0, 0.06), 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
            }
        }

        &.p-highlight {
            .p-steps-number {
                background: $stepsItemBg;
                color: $primaryColor;
            }

            .p-steps-title {
                color: $primaryColor;
            }
        }

        &.p-disabled {
            opacity: 1;
        }

        &:before {
            border-top-width: 2px;
            margin-top: calc(-1rem + 1px);
        }

        &:first-child {
            &::before {
                width: calc(50% + #{math.div($stepsItemNumberWidth, 2)});
                transform: translateX(100%);
            }
        }

        &:last-child {
            &::before {
                width: 50%;
            }
        }
    }
}
