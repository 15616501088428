.p-autocomplete {
    .p-autocomplete-multiple-container {
        padding: .25rem .25rem;
        gap: .25rem;

        .p-autocomplete-token {
            border-radius: 4px;
            margin: 0;

            .p-autocomplete-token-icon {
                margin-left: .375rem;
            }
        }


        .p-autocomplete-input-token {
            margin-left: .5rem;
        }

        &:has(.p-autocomplete-token) {
            .p-autocomplete-input-token {
                margin-left: .5rem;
            }
        }
    }

    &.p-disabled {
        opacity: 1;
    }
}