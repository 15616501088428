.p-dropdown {
    box-shadow: $inputShadow;

    .p-dropdown-label {
        box-shadow: none;
    }

    &.p-disabled {
        opacity: 1;
        background-color: $disabledInputBg;

        .p-dropdown-label {
            color: $textSecondaryColor;
        }
    }
}

.p-dropdown-panel {
    .p-dropdown-items {
        .p-dropdown-item {
            .p-dropdown-check-icon {
                margin-left: -0.375rem;
                margin-right: 0.375rem;
            }
        }
    }
}
