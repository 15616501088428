.p-treeselect-panel {
    .p-tree {
        padding: $inputListPadding;
    }
}

.p-treeselect {
    box-shadow: $inputShadow;

    &.p-disabled {
        opacity: 1;
        background-color: $disabledInputBg;

        .p-treeselect-label {
            color: $textSecondaryColor;
        }
    }

    &.p-treeselect-chip {
        .p-treeselect-label-container {
            .p-treeselect-token {
                border-radius: $inputChipBorderRadius;
            }   
        }
    }
}