.p-tabview {
    .p-tabview-ink-bar {
        z-index: 1;
        display: block;
        position: absolute;
        bottom: 0;
        height: 1px;
        background-color: $primaryColor;
        transition: 250ms cubic-bezier(0.35, 0, 0.25, 1);
    }

    .p-tabview-nav {
        position: relative;
        
        li {
            .p-tabview-nav-link {
                transition: background-color $transitionDuration, color $transitionDuration, border-color $transitionDuration, box-shadow $transitionDuration, outline-color $transitionDuration;
            }
        }
    }

    .p-tabview-nav-btn.p-link {
        color: $actionIconColor;
        transition: $actionIconTransition;
        box-shadow: $raisedButtonShadow;
        box-shadow: 0px 0px 10px 50px rgba(255, 255, 255, 0.6);

        &:hover {
            color: $actionIconHoverColor;
        }
    }
}