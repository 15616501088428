.p-chips {
    .p-chips-multiple-container {
        padding: .25rem .25rem;
        gap: .25rem;

        .p-chips-token {
            border-radius: 4px;
            margin: 0;
            
            .p-chips-token-icon {
                margin-left: .375rem;
            }
        }


        .p-chips-input-token {
            margin-left: .5rem;
        }

        &:has(.p-chips-token) {
            .p-chips-input-token {
                margin-left: .5rem;
            }
        }
    }

    &.p-disabled {
        .p-chips-multiple-container {
            opacity: 1;
            background-color: $disabledInputBg;
        }
    }
}