$primaryColor: #37346f !default;
$primaryDarkColor: #221F5C !default;
$primaryDarkerColor: #37346f !default;
$primaryLightColor: #bfc0fe !default;
$primaryTextColor: #ffffff !default;

$highlightBg: #EFF6FF !default;
$highlightTextColor: $primaryDarkerColor !default;
$highlightFocusBg: rgba($primaryColor, .24) !default;

@import './_variables';
@import './_fonts';
@import '../theme-base/_components';
@import './extensions/_extensions';
