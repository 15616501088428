.p-column-filter-overlay-menu {
    padding: .75rem;
    display: flex;
    flex-direction: column;
    gap: $inlineSpacing;

    .p-column-filter-operator {
        padding: 0;
    }

    .p-column-filter-constraints {
        display: flex;
        flex-direction: column;
        gap: $inlineSpacing;
    }

    .p-column-filter-constraint {
        padding: 0 0 0 0;

        .p-column-filter-remove-button {
            margin-bottom: $inlineSpacing;
        }

        &:last-child {
            .p-column-filter-remove-button {
                margin-bottom: 0;
            }
        }
    }

    .p-column-filter-add-rule {
        padding: 0;
    }

    .p-column-filter-buttonbar {
        padding: 0;
    } 
}