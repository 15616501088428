.p-terminal {
	background: $inputBg;
	color: $inputTextColor;
	border: $inputBorder;
	padding: $inputPadding;
    border-radius: $borderRadius;

    .p-terminal-prompt {
        margin-right: .25rem;
    }

    .p-terminal-response {
        margin: 2px 0;
    }
}
