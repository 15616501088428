.p-timeline {
    .p-timeline-event-marker {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &::before {
            content: ' ';
            border-radius: 50%;
            width: .375rem;
            height: .375rem;
            background-color: $primaryColor;
        }

        &::after {
            content: ' ';
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0px 0.5px 0px 0px rgba(0, 0, 0, 0.06), 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
        }
    }
}



