.p-fieldset {
    padding: 0 1.125rem 1.125rem 1.125rem;
    margin: 0;

    &.p-fieldset-toggleable {
        .p-fieldset-legend {
            a {
                padding: .5rem .75rem;
        
                .p-fieldset-legend-text {
                    padding: 0;
                }
        
                .p-fieldset-toggler {
                    color: $textSecondaryColor;
                }
            }

            &:hover {
                background-color: $shade100;
            }
        }
    }

    .p-fieldset-legend {
        border: 0 none;
        padding: 0;
        margin-bottom: 0.375rem;

        span {
            padding: .5rem .75rem;
        }
    }

    .p-fieldset-content {
        padding: 0;
    }
}