.p-inputswitch {
    .p-inputswitch-slider {
        border: 0 none;
    }

    &.p-highlight {
        p-inputswitch-slider:before {
            left: 1.25rem;
            transform: none;
        }
    }

    &.p-invalid > .p-inputswitch-slider {
        background: $errorColor;


        &:before {
            background: $shade000;
        }
    }
}